import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { navigate } from 'gatsby';
import Shop from "../components/shop.jsx"

const City = ({location}) => {
  
  return (
    <div>
        <Shop header={{
            title: "Hot Wheels City",
            text: "Hot Wheels City ist am besten für die jüngsten Hot Wheels Fans und sollten im Alter zwischen 3 und 8 Jahren alt sein. Die Hot Wheels City Reihe von Spielset ist besonders bunt mit viele Kartoonische Tieren. Sie können die verschiedenen Reihen von Sets auch beliebig miteinander mischen, da alle Hot Wheels sind miteinander kompatibel sind.",
            defaultCategory: "City"
        }} location={location} />
    </div>
  )
}

export default City
